
// ------------------------------ filter-search-result -------------------------------
// .filter-bar-inputnumber {
//     .p-inputtext:enabled:hover {
//         border: 1px solid #2E90FA !important;
//     }
// }

.section-filter-search-result {
    .view-map-btn {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        border: 1px solid #1570EF;
        background: #1570EF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .filter-btn {
        border: 2px solid #EAECF0;
        background-color: #fff;
    }

    .background-result-filter {
        border: 1px solid #EAECF0;
        background: #FFF;
    }

    .title-filter {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px
    }

    span.p-slider-range.ng-star-inserted {
        width: 254px;
    }

    .slider-view-map {
        .p-slider.p-slider-horizontal {
            width: 230px !important;
        }
    }

    .p-slider .p-slider-range {
        background: #2E90FA;
        box-shadow: none;
        width: 254px;
        height: 8px;
        border-radius: 6px;
    }

    .p-slider .p-slider-handle {
        border: 1.5px solid #2E90FA;
        box-shadow: none;
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    .p-slider.p-slider-horizontal .p-slider-handle {
        margin-top: -12px;
    }

    .p-slider.p-slider-horizontal {
        height: 8px;
        width: auto;
        border-radius: 6px;
    }

    .p-sidebar {
        width: 342px;
    }

    .p-slider .p-slider-handle:hover {
        background-color: #2E90FA;
        box-shadow: none;
    }

    span.p-checkbox-icon.pi.pi-check {
        width: 14px !important;
        height: 14px !important;
    }

    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: #2E90FA;
        border: #2E90FA;
        box-shadow: none;
    }

    .p-sidebar .p-sidebar-content {
        padding: 16px !important;
    }

    // checkbox
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: #2E90FA;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #2E90FA;
        background: #EFF8FF;
    }

    .p-checkbox .p-checkbox-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .p-checkbox {
        width: 20px !important;
        height: 20px !important;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #1570EF;
        font-weight: 600;
        font-size: 14px;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
        background: #EFF8FF;
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
        border-color: #2E90FA;
        box-shadow: none;
    }

    .itemFilter {
        color: #344054;
        /* Text md/Regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
}

// --------------------------------------Sort FLIGHT searching result---------------------------------
.hotel-sort .p-dropdown {
    width: 100%
}

.sort-by {
    .p-dropdown {
        border-radius: 8px;
        border: 1px solid #D0D5DD;

        .p-dropdown-label {
            left: 0;
            top: 0;
            padding: 10px 0 10px 16px;
        }

        .p-inputtext {
            padding: 10px 16px;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #344054;
        }

        .p-dropdown-items {
            width: 240px;
            position: absolute;
            right: 0;
            top: 5px;
            background-color: white;
            border-radius: 8px;
            overflow: hidden;
            z-index: 2 !important;
            border: 1px solid #EAECF0;
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        }

        span .show-icon {
            display: none;
        }

        .p-highlight {
            background-color: #F9FAFB !important;
            color: #344054 !important;
            font-weight: 600;
            border-radius: 6px;

            span .show-icon {
                display: inline;
            }
        }

        .p-dropdown-item {
            height: unset !important;
            margin: 2px 6px;
        }

        .p-dropdown-item:hover {
            background-color: #F9FAFB !important;
            border-radius: 6px;
        }

        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 16px 10px 0;
        }

        .pi-chevron-down {
            width: 20px;
            height: 20px;
        }

        .pi-chevron-down::before {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 20px;
        }

        .p-overlay {
            z-index: 3 !important;
        }
    }
}

// -------------------------------------*********--------------------------
// ---------------------------------- price-details -------------------------------
.price-detail {
    box-shadow: 0px 1px 2px 0px #1018280D;

    .p-dialog .p-dialog-header {
        border-bottom: 1px solid #EAECF0;
        height: 73px;
    }

    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon {
        color: #667085;
    }

    .p-inputswitch {
        width: 100%;
        height: 100%;
    }

    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }

    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }

    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        left: 0.15rem;
        margin-top: -8px;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(1rem);
    }

    .soctrip-coins {
        border: 1px solid #D0D5DD;
        background: #FCFCFD;
    }

    .left-card-voucher {
        border: 1px solid #84CAFF;
        background: #EFF8FF;
        border-right: 1px dashed #84CAFF;
    }

    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }

    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-dialog .p-dialog-content {
        background: #fff !important;
        height: auto;
        padding: 0;
        // padding-top: 12px !important;
        // padding-bottom: 30px !important;
        // padding-left: 24px !important;
        // padding-right: 24px !important;
    }

    .p-component-overlay {
        background-color: rgba(0, 0, 0, 0.4) !important;
    }

    .left-card-voucher-active {
        border: 1px solid #1570EF;
        background: #EFF8FF;
        border-right: 1px dashed #1570EF;
    }

    .left-card-voucher-inactive {
        border: 1px solid #D0D5DD !important;
        background: #F2F4F7 !important;
        border-right: 1px dashed #D0D5DD !important;
    }

    .private-left-active {
        border: 1px solid #FF4405;
        background: #FFE6D5;
        border-right: 1px dashed #FF4405;
    }

    .private-left {
        border: 1px solid #ff9c66;
        background: #FFE6D5;
        border-right: 1px dashed #ff9c66;
    }

    .right-card-voucher {
        border: 1px solid #84CAFF;
        background: #FFF;
        border-left: none;
    }

    .right-card-voucher-avtive {
        border: 1px solid #1570EF;
        background: #FFF;
        border-left: none;
    }

    .private-right {
        border: 1px solid #ff9c66;
        background: #FFF;
        border-left: none;
    }

    .private-right-active {
        border: 1px solid #FF4405;
        background: #FFF;
        border-left: none;
    }

    .right-card-voucher-inactive {
        border: 1px solid #D0D5DD;
        background: #FFF;
        border-left: none;
    }

    .confirm-voucher-btn {
        background: #1570EF;
        &:hover {
            background: #2e90fa;
        }
    }

    .cancel-voucher-btn {
        border: 1px solid #D0D5DD;
        background: #fff;
    }

    .promo-code-card {
        background: #F9FAFB;
        border: 1px solid #EAECF0;

        .input-promo-code {
            border: 1px solid;
            background: #fff;
        }
    }

    .text-total-price {
        background: linear-gradient(263.89deg, #FF692E, #FF4405);
        -webkit-background-clip: text;
        color: transparent;
    }

    .p-dialog .p-dialog-footer {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

// ------------------------------------**********---------------------------
// ------------------------------------My booking details-----------------------------
.my-booking-details-section {
    .p-dialog {
        background: #fff !important;
        border-color: #EAECF0 !important;
        border-radius: 12px !important;
        max-height: 800px !important;
    }

    .p-dialog .p-dialog-content {
        padding: 0px 24px 0px 24px !important;
    }

    .p-dialog .p-dialog-footer {
        border-radius: 12px !important;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }

    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-dialog-header {
        display: flex !important;
        align-items: flex-start !important;
        justify-content: space-between !important;
        flex-shrink: 0 !important;
        border-radius: 12px !important;
    }
}

// ------------------------------------**********---------------------------
// ------------------------------------Contact-info-----------------------------
.contact-info {
    .p-inputtext {
        padding: 10px 14px !important;
        border-radius: 8px !important;
    }

    .p-inputswitch {
        width: 36px;
        height: 20px;
    }

    .p-inputswitch.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #1570EF;
    }

    .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #1570EF;
    }

    .p-inputswitch .p-inputswitch-slider:before {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        transform: translateX(-1px);
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(0.9rem);
    }

    .iti--separate-dial-code .iti__selected-flag {
        background-color: transparent !important;
        border-radius: 8px 0 0 8px;
        outline: none;
    }

    .iti.iti--allow-dropdown.iti--separate-dial-code.iti--show-flags {
        width: 100%;
    }

    .iti__country-list--dropup {
        margin-bottom: 6px !important;
    }

    .iti__country-list {
        margin-top: 6px !important;
    }

    .p-inputtext:enabled:hover {
        border-color: #ced4da !important;
    }

    .p-inputtext.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }

    .tel-input.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }

    .iti__arrow {
        margin: 0 12px 28px 4px;
        border-left: none;
        border-right: none;
        border-top: none;
    }

    .iti__selected-flag .iti__flag {
        margin-left: 3px;
        margin-right: 4px;
        scale: 1.35;
        border-radius: 4px;
    }

    .iti__flag {
        border-radius: 4px;
    }

    .iti__arrow--up {
        border-bottom: none;
        border-top: none;
    }
}

// -------------------------------------*********--------------------------
// ---------------------------------- booking-details -------------------------------
.booking-detail {
    box-shadow: 0px 1px 2px 0px #1018280D;
}

.booking-details-checkout-section {
    box-shadow: 0px 1px 2px 0px #1018280D;
}

// -------------------------------------*********--------------------------
// ---------------------------------- search booking id modal -------------------------------
.search-booking-id-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }

    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
    }

    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 32px !important;
        padding-right: 32px !important;
    }

    .p-dialog-header {
        justify-content: center !important;
        position: relative;
    }

    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }

    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }

    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }

    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .iti--separate-dial-code .iti__selected-flag {
        background-color: #fff !important;
        border-radius: 8px 0 0 8px;
        outline: none;
    }

    .iti.iti--allow-dropdown.iti--separate-dial-code.iti--show-flags {
        width: 100%;
    }

    .iti__country-list--dropup {
        margin-bottom: 6px !important;
    }

    .iti__country-list {
        margin-top: 6px !important;
    }

    .p-inputtext:enabled:hover {
        border-color: #ced4da !important;
    }

    .p-inputtext.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }

    .tel-input.ng-invalid.ng-touched {
        border-color: #e24c4c;
    }
}

.albums-hotel .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: none !important;
    color: #667085 !important;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.albums-hotel .p-tabview-nav li {
    height: 31px;
}

.albums-hotel .p-tabview-nav li:not(.p-highlight):not(.p-tabview-ink-bar) {
    border-bottom: solid 1px;
    @apply border-palette-gray-200
}

.albums-hotel .p-highlight.ng-star-inserted {
    border-bottom: 2px solid #175CD3;
}

.albums-hotel .p-tabview .p-tabview-nav {
    padding-top: 16px !important;
}

.albums-hotel .grid {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.albums-hotel .p-tabview .p-tabview-panels {
    margin-top: 16px !important;
}

.albums-hotel .p-tabview .p-tabview-panels {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

// -------------------------------------*********--------------------------
@layer components {

    //Title at beginning component at homepage
    .title-hotel {
        @apply text-xl font-semibold not-italic text-palette-gray-800 font-inter leading-[30px];
    }

    //Sub title at beginning component at homepage
    .sub-title-hotel {
        @apply text-sm font-normal not-italic text-palette-gray-500 font-inter leading-5 mt-[2px];
    }

    //Text view more at homepage
    .view-more {
        @apply text-base not-italic font-semibold text-palette-blue-700 font-inter;
    }

    //Common text with size 14 and font weight 500
    .text-soctrip-small {
        @apply text-sm not-italic font-medium font-inter leading-5;
    }
}

.popupCustomMapView {
    margin-bottom: 50px;

    .leaflet-popup-content-wrapper {
        padding: 0;

        .leaflet-popup-content {
            margin: 0;
            width: 292px !important;
        }
    }

    .leaflet-popup-tip {
        display: none;
    }
}

.popupCustomMapViewDetail {
    @extend .popupCustomMapView;
    margin-bottom: 70px;
}

.tooltipCustom.p-tooltip {
    .p-tooltip-text {
        background-color: #fff;
        color: #344054;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 600;
    }
}


// **********************************************************
// ------------------------- Toast --------------------------
$bg_error: #FFFBFA;
$bd_error: #FDA29B;
$color_error: #B42318;
$bg_warning: #FFFCF5;
$bd_warning: #FEC84B;
$color_waring: #B54708;
$bg_success: #F6FEF9;
$bd_success: #6CE9A6;
$color_success: #027A48;
$bg_info: #FFF;
$bd_info: #D0D5DD;
$color_info: #344054;
$close_icon_error: '/assets//images//common-icon//error-close.svg';
$close_icon_warning: '/assets//images//common-icon//warning-close.svg';
$close_icon_success: '/assets//images//common-icon//success-close.svg';
$close_icon_info: '/assets//images//common-icon//info-close.svg';

.toast-booking {
    .p-toast {
        font-family: Inter !important;
        font-weight: 700 !important;
        opacity: 1 !important;
        top: 5.75rem;
        width: 29rem;
        right: 1.5rem;
        max-height: 17rem !important;
        overflow: hidden;
    }

    .p-toast-message-error {
        border: 1px solid $bd_error !important;
        background-color: $bg_error !important;
        opacity: 1 !important;
        color: $color_error !important;
        border-radius: 12px !important;

        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_error) !important;
            background-repeat: no-repeat !important;
        }
    }

    .p-toast-message-warning {
        color: $color_waring !important;
        background-color: $bg_warning !important;
        border: 1px solid $bd_warning !important;
        border-radius: 12px !important;

        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_warning) !important;
            background-repeat: no-repeat !important;
        }
    }

    .p-toast-message-success {
        color: $color_success !important;
        background-color: $bg_success !important;
        border: 1px solid $bd_success !important;
        border-radius: 12px !important;

        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_success) !important;
            background-repeat: no-repeat !important;
        }
    }

    .p-toast-message-info {
        color: $color_info !important;
        background-color: $bg_info !important;
        border: 1px solid $bd_info !important;
        border-radius: 12px !important;

        .p-toast-icon-close-icon {
            width: 1.25rem !important;
            height: 1.25rem !important;
            background-image: url($close_icon_info) !important;
            background-repeat: no-repeat !important;
        }
    }

    .p-link:focus {
        box-shadow: none !important;
    }

    .p-toast-icon-close {
        height: 100% !important;
    }

    .p-toast-message-content {
        width: 100% !important;
        border: none !important;
        padding: 0 !important;
    }

    .p-toast-message {
        font-family: 'Inter', 'sans-serif' !important;
        display: flex !important;
        box-shadow: none !important;
        border-radius: none !important;
        transition: none !important;
        height: fit-content !important;
        white-space: pre-wrap !important;
        width: 100%;
        padding: 1rem !important;
    }

    .p-toast-icon-close:hover {
        background: none !important;
    }

    .p-toast-icon-close-icon::before {
        content: none !important;
    }

    .toast-content {
        height: fit-content;
        line-height: 1.25rem;
        width: 100%;
        margin: 0 0.75rem;
        font-family: 'Inter', 'sans-serif';
        font-size: 0.875rem;
        white-space: pre-wrap;
    }
}

.ota-location-form {
    .p-inputtext {
        min-height: unset;
    }

    .p-dropdown .p-dropdown-label {
        color: #344054;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
    }

    p-dropdown.p-element.p-inputwrapper {
        height: 44px;
    }

    .p-dropdown.p-component {
        min-width: 100%;
        min-height: unset;
        height: 44px;

        &.p-disabled {
            background-color: #EAECF0 !important;
            opacity: 1 !important;

            .p-dropdown-label.p-placeholder {
                opacity: .6 !important;
            }
        }
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        margin: 0 !important;
        height: 44px;
        min-height: unset;
        width: 1rem;
        color: #98A2B3;
    }

    .p-dropdown .p-dropdown-label .p-inputtext {
        align-items: center;
        width: 160px;
        min-height: 44px;
        height: 44px;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        color: #344054 !important;
    }

    .p-dropdown-trigger {
        width: 3rem !important;
        margin: 0 !important;
        padding: 12px 8px 12px 0;

        .pi.pi-chevron-down {
            font-size: 20px;
        }
    }

    .distance-field {
        .is-disable {
            .p-inputnumber-input {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }
        }

        span.p-inputnumber.p-component {
            width: 100%;
            height: 44px;

            &:hover {
                border-color: #84caff !important;
            }
        }

        .p-inputnumber-input {
            min-height: unset;
            border-radius: 0.5rem 0 0 0.5rem;

            &:hover {
                border-color: #84caff !important;
            }
        }

        .p-dropdown.p-component {
            border-radius: 0 0.5rem 0.5rem 0;
            height: 44px !important;
        }
    }
}

// **********************Input phone number *******************
.input-phone-booking {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    height: 44px;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    .input-phone-booking .p-inputtext {
        width: 100%;
        height: 42px;
        min-height: 42px;
        padding: 12px 4px 12px 16px;
        border: none !important;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        border: none !important;
        box-shadow: none;
    }

    .p-dropdown {
        border: none !important;
    }
}

.input-phone-booking .p-inputtext {
    width: 100%;
    height: 42px;
    min-height: 42px;
    padding: 12px 4px 12px 12px;
    border: none;
    outline: none;
    border-radius: 0px 8px 8px 0px;

    &:focus {
        border: none !important;
        outline: none;
        box-shadow: none;
    }
}

// Custom styles for tag p-dropdown
.input-phone-booking .p-dropdown {
    padding: 0px !important;
    display: flex;
    align-items: center;
    width: 98px;
    height: 40px;
    border: 1px solid transparent;
}

.input-phone-booking .p-dropdown-panel {
    min-width: 240px;
}

// OTA
.ota-custom {
    .custom-input-mask {
        &.is-invalid {
            .p-inputmask {
                border: 1px solid #dc3545 !important;
    
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }
    
                &:hover {
                    border: 1px solid #dc3545 !important;
                }
            }
        }
    }
    .custom-input {
        &.position-top {
            .p-overlay.p-component {
                top: auto !important;
                bottom: 46px !important;
            }
            .p-dropdown-panel {
                box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            }
        }
        &.custom-h-180 {
            .p-dropdown-items-wrapper {
                max-height: 160px !important;
            }
            .p-dropdown-panel {
                box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            }
        }

        .p-inputtext.p-component {
            font-family: 'Inter';
            height: 44px;
            min-height: 44px;
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            padding: 12px 16px;
            height: 44px;
            font-size: 14px;
            line-height: 20px;
            outline: none;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &.is-disable {
                color: #344054;
                background-color: #EAECF0 !important;
                opacity: 1 !important;
            }

            &.is-invalid {
                border: 1px solid #dc3545 !important;

                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }

                &:hover {
                    border: 1px solid #dc3545 !important;
                }
            }

            &:hover {
                border: 1px solid #84caff;
            }
        }

        @media (max-width: 768px) {
            .p-inputtext.p-component {
              font-size: 16px;
            }
          }

        .unit-custom {
            .p-dropdown {
                padding: 4px;
                border-radius: 0px 8px 8px 0;

                .p-dropdown-label {
                    font-weight: 600 !important;
                }
            }
        }

        .p-dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            padding: 4px 16px 4px 4px;
            border-radius: 8px;
            min-width: 120px;

            .p-dropdown-item {
                border-radius: 6px;
                color: #344054 !important;
                font-weight: 400 !important;

                &.p-highlight {
                    background-color: #f2f4f7;
                }
            }

            .p-dropdown-items-wrapper {
                padding: 4px;
                font-family: Inter, sans-serif;
                font-size: 14px;
                line-height: 20px;
                scrollbar-width: thin;
                scrollbar-color: #888 #f2f2f2;

                &::-webkit-scrollbar {
                    width: 6px !important;
                }
            }

            .p-dropdown-label {
                min-height: 44px;
                margin: 0;
                
                &.p-inputtext {
                    color: #344054;
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    line-height: 20px;
                }

                @media (max-width: 768px) {
                    &.p-inputtext {
                      font-size: 16px;
                    }
                  }

                &.p-placeholder {
                    color: #98a2b3;
                    font-weight: 400;
                    min-height: 44px;
                }

                div {
                    justify-content: start;
                }
            }
        }

        // checkbox
        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: #D0D5DD;
            background-color: #F2F4F7;
        }

        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: #1570EF;
            background: #1570EF;
            color: #fff;
        }

        .p-checkbox .p-checkbox-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 4px !important;
        }

        .p-checkbox {
            width: 20px !important;
            height: 20px !important;
        }

        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            color: #fff;
            font-weight: 600;
            font-size: 12px;
        }

        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
            background: #1570EF;
        }

        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
            border-color: #1570EF;
            box-shadow: none;
        }

        .p-dropdown-sort .p-dropdown {
            height: 38px;
            padding: 0 16px 0 8px;

            .p-inputtext {
                padding: 0 8px;
                min-width: 116px;
                min-height: 20px;
                line-height: 20px;
            }

            .p-dropdown-item {
                padding: 12px;
            }
        }

        .is-invalid {
            .p-dropdown.p-component {
                border: 1px solid #dc3545 !important;
                transition: all 0.3s;

                &.p-dropdown-open,
                &.p-focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                }
            }
        }

        .p-radiobutton {
            width: 20px;
            height: 20px;
            transition: all 0.3s;

            &.p-radiobutton-checked .p-radiobutton-box {
                border: 1px solid #1570ef;
                background: #eff8ff;
            }

            .p-radiobutton-box {
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid #d0d5dd;

                &.p-focus,
                &:hover {
                    border: 1px solid #1570ef;
                    box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
                }

                .p-radiobutton-icon {
                    width: 8px;
                    height: 8px;
                    background: #1570ef;
                }
            }
        }

        .is-invalid .p-radiobutton-box {
            border: 1px solid #dc3545;
        }

        .p-inputgroup .p-inputtext {
            border-radius: 8px 0px 0px 8px !important;
        }

        .hide-dropdown-header .p-dropdown-header {
            display: none;
        }

        &.custom-h-400 {
            .p-dropdown-items-wrapper {
                max-height: 408px !important;
            }
        }

        // hotel-management/payment-info
        &.dropdown-payment-disable {
            .p-dropdown.p-disabled {
                background-color: #f2f4f7;
                opacity: 1;
            }
        }
    }

    .custom-phone {
        .is-invalid .p-dropdown.p-component {
            border: none !important;
        }
    }
    
    .custom-calendar-w-full {
        .p-calendar {
            width: 100%;
        }
    }

    .custom-calendar {
        .is-invalid .p-inputtext.p-component {
            border: 1px solid #dc3545;
        }

        .p-inputtext {
            height: 44px;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            border: 1px solid #d0d5dd;
            border-right: none;

            &:hover {
                border: 1px solid #d0d5dd;
                border-right: none;
            }

            &:focus {
                box-shadow: none;
            }

            &:enabled:focus {
                border: 1px solid #d0d5dd !important;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                border-right: none !important;
            }
        }

        .p-button {
            height: 48px;
            border: 1px solid #d0d5dd;
            border-left: none;

            &:enabled:hover {
                background: none;
                color: #D0D5DD;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .p-calendar .p-datepicker {
            top: 50px !important;
            left: 0 !important;
        }

        &.reschedule-custom {
            &.left-side {
                .p-calendar .p-datepicker {
                    top: auto !important;
                    bottom: 50px !important;
                    left: 0 !important;
                }
            }
            &.right-side {
                .p-calendar .p-datepicker {
                    top: auto !important;
                    bottom: 50px !important;
                    left: auto !important;
                    right: 0 !important;
                }
            }
        }

        .p-datepicker {
            padding: 16px;
            padding-bottom: 16px;
        }

        .p-datepicker table td {
            padding: 0 !important;
            font-family: 'Inter' !important;
        }

        .p-datepicker table td>span.p-highlight {
            color: white;
            background: #1570EF;
            border-radius: 4px;
        }

        .p-datepicker table tbody {
            @apply space-y-1
        }

        .p-datepicker table td.p-datepicker-today>span {
            background-color: white;
            color: #1570EF;
            border-radius: 4px;
        }

        .p-datepicker table td>span:focus {
            box-shadow: none;
        }

        .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
            box-shadow: none;
        }

        .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
            border-radius: 4px;
        }

        .p-datepicker .p-datepicker-header {
            border-bottom: none;
        }

        .p-datepicker-month,
        .p-datepicker-year {
            color: #344054;
        }

        .p-datepicker-month:enabled:hover,
        .p-datepicker-year:enabled:hover {
            color: #1570EF !important;
        }

        .p-link:focus {
            box-shadow: none !important;
        }

        .p-datepicker-prev:focus {
            box-shadow: none !important;
        }

        .p-datepicker-header {
            padding: 0;
        }

        .p-monthpicker-month:not(.p-disabled):focus {
            box-shadow: none !important;
        }

        .p-yearpicker-year:not(.p-disabled):focus {
            box-shadow: none !important;
        }

        .p-yearpicker-year.p-highlight {
            color: #1570EF !important;
        }

        .p-input-icon-right>i {
            top: 25% !important
        }
    }

    .custom-dropdown {
        .p-dropdown .p-dropdown-label.p-placeholder {
            margin: 0;
        }

        .hidden-dropdown {
            .p-overlay {
                display: none;
            }
        }

        .custom-date {
            .p-dropdown-items-wrapper {
                padding: 24px;
                width: 328px;
                height: 264px;

                .p-dropdown-items {
                    display: grid;
                    grid-template-columns: repeat(7, minmax(0, 1fr));
                    border-radius: 8px;

                    .p-element {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .p-dropdown-item {
                            width: 40px;
                            height: 40px !important;
                        }
                    }
                }
            }
        }
    }

    .custom-group {
        .p-inputgroup .p-inputtext {
            border-radius: 8px 0px 0px 8px !important;
            min-height: 44px;
            height: 44px;

            &.is-invalid {
                border: 1px solid #dc3545;
            }
        }
    }

    .checkbox-disabled {
        .p-checkbox-box.p-disabled {
            background-color: #667085;
        }
    }

    .invalid {
        .p-calendar {
            border: 1px solid red;
            border-radius: 8px;
        }
    }

    .custom-radio {
        .p-radiobutton {
            height: 20px !important;
        }
    }

    .custom-disabled {
        .p-inputtext.p-component {
            background-color: #F2F4F7;
            border: 1px solid #D0D5DD;
            color: #344054;
            opacity: 1;

            &:hover {
                border: 1px solid #D0D5DD;
            }
        }
    }

    .custom-switch {
        .p-inputswitch {
            width: 36px;
            height: 20px;

            .p-inputswitch-slider:before {
                width: 16px;
                height: 16px;
                margin-top: -8px;
                left: 2px;
            }

            &.p-inputswitch-checked .p-inputswitch-slider {
                background: #1570EF;

                &:before {
                    transform: translateX(16px);
                }
            }
        }
    }

    .custom-textarea {
        &.p-inputtextarea {
            min-height: 96px;
            &:hover {
                border: 1px solid #84caff;
            }
            &:focus {
                border: 1px solid #84caff;
                box-shadow: 0px 0px 0px 3px #eaf3ff, 0px 1px 2px 0px #84caff;
            }
            &.is-invalid {
                border: 1px solid #dc3545;
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
                    border: 1px solid #dc3545 !important;
                }
            }
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 300s ease-in-out 0s;
    }
}


// ********************** Calendar *******************
.calendar-dropdown {
    .p-dropdown {
        height: 36px;
        width: 200px;
        align-items: center;
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        height: 36px;
        padding: 8px 0 8px 16px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-inputtext {
        min-height: 0;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #344054;
    }

    .p-dropdown .p-dropdown-trigger {
        margin-right: 16px;
    }
}

.calendar-dropdown-right {
    .p-dropdown {
        height: 44px;
        width: 100%;
        align-items: center;
    }

    .p-dropdown .p-dropdown-label.p-placeholder {
        height: 36px;
        padding: 9px 0 8px 16px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .p-inputtext {
        min-height: 0;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #344054;
    }

    .p-dropdown .p-dropdown-trigger {
        margin-right: 16px;
    }

    .p-disabled {
        background-color: #F2F4F7;
    }

    .p-ripple .p-element .p-dropdown-item {
        width: 250px;
    }

    .p-overlay {
        max-width: 100%;
        left: -1px !important;
    }
}

.calendar-from-input-booking {
    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-button:enabled:hover {
        background: none;
        color: #D0D5DD;
    }

    @media (max-width: 1800px) {
        .p-calendar .p-datepicker {
            width: 100%;
        }

        .p-calendar {
            width: 100% !important;
        }
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    .p-datepicker table td.p-datepicker-today>span {
        background-color: #1570EF;
        color: white;
    }

    .p-datepicker table td>span.p-highlight {
        border: 1px solid #1570EF;
        background-color: rgb(239 248 255);
        color: #1570EF;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}

.calendar-to-input-booking {
    .p-calendar-w-btn .p-inputtext {
        min-height: 0;
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: none;
        border-left: 1px solid #D0D5DD;
    }

    .p-calendar-w-btn .p-datepicker-trigger {
        height: 44px;
        border-bottom: 1px solid #D0D5DD;
        border-top: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        border-left: none;
    }

    .p-button:enabled:hover {
        background: none;
        color: #D0D5DD;
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-datepicker table td {
        padding: 1px;
    }

    @media (max-width: 1800px) {
        .p-calendar .p-datepicker {
            left: 0px !important;
            width: 100%;
        }

        .p-calendar {
            width: 100% !important;
        }
    }

    @media (min-width: 1800px) and (max-width: 1920px) {
        .p-calendar .p-datepicker {
            left: -150px !important;
        }
    }

    .p-datepicker table td.p-datepicker-today>span {
        background-color: #1570EF;
        color: white;
    }

    .p-datepicker table td>span.p-highlight {
        border: 1px solid #1570EF;
        background-color: rgb(239 248 255);
        color: #1570EF;
    }

    .p-inputtext:focus {
        box-shadow: none;
    }

    .p-inputtext:enabled:focus {
        border: 1px solid #D0D5DD !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none !important;
    }
}

.calendar-input-text-booking {
    .p-input-icon-left>.p-inputtext {
        height: 44px;
        min-height: 0;
        width: 100%;
    }

    .p-input-icon-left>i:first-of-type {
        top: 19px;
    }

    .p-inputtext:enabled:hover {
        border-color: #84CAFF;
    }

    .p-input-icon-left>.p-inputtext {
        padding-left: 33px;
    }

    .p-input-icon-left>.p-inputnumber {
        width: 100%;
    }

    .p-inputwrapper {
        display: block;

        .p-inputnumber {
            height: 44px !important;
            width: 100%;
        }
    }
}


.calendar-filter-discount {
    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        border-color: #1570EF !important;
        background: #1570EF !important;
    }

    .p-slider .p-slider-handle {
        height: 24px;
        width: 24px !important;
        border-color: #1570EF !important;
        top: -10% !important;
    }

    .p-slider .p-slider-range {
        align-items: center !important;
        height: 8px !important;
        background: #1570EF !important;
    }

    .p-slider-horizontal .p-slider-range {
        top: -2px !important;
    }
}

.calendar-filter-from {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: -131px !important;
    }

    .p-inputtext {
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #d0d5dd;
        font-family: Inter, sans-serif;
        font-size: 14px;
    }

    .p-inputtext:enabled:hover {
        border: 1px solid #84caff;
    }

    .p-inputtext:enabled:focus {
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #84caff;
        font-weight: 400;
    }

    .p-calendar {
        width: 100% !important;
    }
}

.calendar-filter-to {
    .p-calendar .p-datepicker {
        top: 50px !important;
        left: -174px !important;
    }

    .p-inputtext {
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #d0d5dd;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
    }

    .p-inputtext:enabled:hover {
        border: 1px solid #84caff;
    }

    .p-inputtext:enabled:focus {
        height: 44px;
        min-height: 44px;
        width: 100% !important;
        border: 1px solid #84caff;
    }

    .p-calendar {
        width: 100% !important;
    }
}

.calendar-promotions {
    .p-datepicker {
        padding: 16px;
        padding-bottom: 16px;
    }

    .p-datepicker table td {
        padding: 0 !important;
        font-family: 'Inter' !important;
    }

    .p-datepicker table td>span.p-highlight {
        color: white !important;
        background: #1570EF !important;
    }

    .p-datepicker table tbody {
        @apply space-y-1
    }

    .p-datepicker table td.p-datepicker-today>span {
        background-color: white;
        color: #1570EF;
    }

    .p-datepicker table td>span:focus {
        box-shadow: none;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: none;
    }

    .p-datepicker .p-datepicker-header {
        border-bottom: none;
    }

    .p-datepicker-month,
    .p-datepicker-year {
        color: #344054;
    }

    .p-datepicker-month:enabled:hover,
    .p-datepicker-year:enabled:hover {
        color: #1570EF !important;
    }

    .p-link:focus {
        box-shadow: none !important;
    }

    .p-datepicker-prev:focus {
        box-shadow: none !important;
    }

    .p-datepicker-header {
        padding: 0;
    }

    .p-monthpicker-month:not(.p-disabled):focus {
        box-shadow: none !important;
    }

    .p-yearpicker-year:not(.p-disabled):focus {
        box-shadow: none !important;
    }

    .p-yearpicker-year.p-highlight {
        color: #1570EF !important;
    }

    .p-input-icon-right>i {
        top: 25% !important
    }
}



.promotions-modal-section {
    .p-component-overlay {
        background-color: rgba(0, 0, 0.2, 0.6) !important;
    }

    .p-dialog .p-dialog-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
        padding-bottom: 2px;
    }

    .p-dialog .p-dialog-content {
        background: #fff !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .p-dialog-header {
        justify-content: flex-start !important;
        position: relative;
    }

    .p-dialog .p-dialog-header-icons {
        position: absolute;
        right: 20px;
    }

    .p-dialog .p-dialog-header .p-dialog-title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        font-family: "Inter";
        color: #101828;
    }

    .p-dialog-header-icons {
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .pi {
        font-size: 12px;
        color: #667085;
        font-weight: 700;
    }

    .p-dialog-header-close-icon {
        width: 12px !important;
        height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

// CUSTOM POPUP
p-confirmdialog.custom-popup {
    .p-dialog {
        width: 544px;

        .p-dialog-header {
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            color: #101828;

            .p-dialog-header-icons {
                .p-dialog-header-close {
                    span {
                        font-size: 20px;
                        color: #667085;
                    }
                }
            }
        }

        .p-dialog-content {
            background-color: white !important;
            padding: 0 1.5rem 1.5rem !important;

            .p-confirm-dialog-message {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin: unset;
                color: #475467;
            }
        }

        .p-dialog-footer {
            button {
                height: 44px;
                font-family: Inter;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                outline: none;

                &:hover {
                    background-color: unset;
                    opacity: .8;
                }

                .p-button-icon {
                    display: none;
                }

                span {
                    font-weight: 600;
                }
            }

            button.btn-cancel-reject {
                color: #344054;
                border: 1px solid #D0D5DD;
            }

            button.btn-cancel-accept {
                color: #FFFFFF;
                background-color: #D92D20;
                border: none;
            }

            button.btn-save-as-draft-reject {
                color: #344054;
                border: 1px solid #D0D5DD;
            }

            button.btn-save-as-draft-accept {
                color: #FFFFFF;
                background-color: #1570EF;
                border: none;
            }

            button.btn-back-accept {
                color: #344054;
                border: 1px solid #D0D5DD;
            }

            button.btn-back-reject {
                color: #FFFFFF;
                background-color: #1570EF;
                border: none;
            }
        }
    }
}

p-confirmdialog.custom-back-popup .p-dialog-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
}


// ********************** FLIGHT SEARCH BAR*******************

.flight-search-bar {
    .form-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #344054
    }

    // radiobutton
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;

        &:hover {
            border-color: #1570EF !important;
        }
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;

        &:hover {
            background: #EFF8FF;
        }
    }

    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF !important;
        background: #EFF8FF !important;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }

    // check box
    .form-input {
        font-size: 14px;
        height: 44px;
        min-height: 44px;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;
    }

    .form-input:has(.box-input:focus) {
        border: 1px solid #84caff;
        box-shadow: 0px 0px 0px 4px #eaf3ff, 0px 1px 2px 0px #84caff;
    }

    .form-input:has(.box-input:hover) {
        border: 1px solid #84caff;
    }
    .form-input:has(.box-input) {
        border: 1px solid #f2f4f7;
    }

    .box-input {
        font-size: 14px;
        width: 100%;
        font-family: 'Inter';
        outline: none;
        color: #344054;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;

        &:focus {
            border: none;
            box-shadow: none;
        }

        &.is-invalid {
            border: none;
        }
    }

    .p-dropdown{
        display: flex;
        align-items: center;
        height: 44px;
        min-height: 44px;
        max-height: 44px;
        width: 100% !important;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px !important;
        font-style: normal;
        line-height: 20px;
        font-weight: 500 !important;
        outline: none;
        background-color: #ffff;
        border: 0 none !important;
        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 0px 10px 0;
        }
        .p-dropdown-label{
            font-size: 14px !important;
            font-family: 'Inter';
            outline: none;
            color: #344054; 
            padding-left: 0px;
            padding-right: 6px;
            font-weight: 500 !important;
            height: 44px;
            min-height: 44px;
            max-height: 44px;            
            &.p-placeholder{
                color: #98A2B3 !important;
            }
        }
    }
    .p-dropdown-panel {
        border: 0 none !important;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        .p-dropdown-items{
            margin: 4px;
            .p-dropdown-item{
                font-weight: 500 !important;
            }
        }
    }
    .p-dropdown:not(.p-disabled).p-focus {
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;
        border: 0 !important;
        background: var(--base-white, #FFF);
        box-shadow: none;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: #F9FAFB;
        background: #e9ecef;
        border-radius: 8px;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #F2F4F7;
        border-radius: 8px;
    }

    @media (max-width: 768px) {
        .box-input {
          font-size: 16px;
        }
    }
      
}

// ******************list search result - flight ************************

.list-airline-tickets .p-carousel-items-container .p-carousel-item:not(:last-child) .carousel-item-container {
    margin-right: 16px;

}

.airline-tickets .p-carousel-content {
    position: relative;
}

.airline-tickets .p-carousel-content ul.p-carousel-indicators {
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: center;
    padding: 2px !important;
}

.airline-tickets .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator button {
    height: 4px !important;
    width: 16px;
    background-color: white;
    border-radius: 2px !important;
    padding: 0 5px 0 5px;
}

.airline-tickets .p-carousel-content ul.p-carousel-indicators li.p-carousel-indicator.p-highlight button {
    background-color: #1570EF !important;
    width: 25px !important;
}

.airline-tickets .p-carousel-container {
    position: relative;
}

.airline-tickets .p-carousel .p-carousel-content .p-carousel-prev {
    position: absolute;
    z-index: 10;
    // top: 0;
    left: 12px;
    height: 40px;
    width: 40px;
    border-radius: 24px;
    color: #1570EF;
    border: 1px solid #1570EF;
    background: #FFF;
    box-shadow: 4px 4px 12px -2px rgba(62, 74, 100, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0;
}

.airline-tickets .p-carousel .p-carousel-content .p-carousel-next {
    position: absolute;
    right: 12px;
    // top: 0;
    z-index: 10;
    height: 40px;
    width: 40px;
    color: #1570EF;
    border: 1px solid #1570EF;
    background: #FFF;
    box-shadow: 4px 4px 12px -2px rgba(62, 74, 100, 0.05);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    margin: 0;
}

.airline-tickets .p-carousel .p-carousel-content .p-carousel-next:focus {
    box-shadow: none;
}

.airline-tickets .p-carousel .p-carousel-content .p-carousel-prev:focus {
    box-shadow: none;
}

.airline-tickets .p-carousel .p-carousel-content .p-carousel-prev:hover,
.airline-tickets .p-carousel .p-carousel-content .p-carousel-next:hover {
    @apply bg-palette-blue-light-50 border border-palette-blue-600 text-palette-blue-600
}


.airline-tickets .p-carousel .p-carousel-content {

    .p-carousel-next,
    .p-carousel-prev {
        padding: 0;
        text-align: center;
    }

    .p-carousel-next .pi-chevron-right,
    .p-carousel-prev .pi-chevron-left {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}

.airline-tickets .p-carousel-content button.p-carousel-prev:disabled {
    display: none;
}

.airline-tickets .p-carousel-content button.p-carousel-next:disabled {
    display: none;
}

.airline-tickets .p-carousel .p-carousel-content {

    .p-carousel-next,
    .p-carousel-prev {
        padding: 0;
        text-align: center;
    }

    .p-carousel-next .pi-chevron-right,
    .p-carousel-prev .pi-chevron-left {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}



.flight-view-detail-sidebar {
    @media only screen and (max-width: 600px) {
        .p-sidebar-right {
            width: 100vw !important;
        }
      }
    .p-sidebar-right {
        width: 80vw;
        max-width: 900px !important;
    }

    .p-sidebar .p-sidebar-header {
        padding: 0px;
        visibility: hidden;
        height: 0px;
    }

    .p-sidebar .p-sidebar-content {
        padding: 0px;
    }

    .p-sidebar {
        background-color: #F9FAFB;
    }
}

.hide-scroll {
    overflow: hidden !important;
}

.contact-information .p-dropdown {
    width: 100%;
    max-height: 44px;
    border: 1px solid #D0D5DD;
}

.contact-information .container-phone-input .p-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px !important;
    border: 1px solid transparent;
}

.contact-information .p-dropdown .p-dropdown-label.p-placeholder {
    font-weight: 400;
    color: #98A2B3;
}

.sort-by-search-result-flight {
    .p-dropdown {
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11 !important;

        .p-dropdown-label {
            left: 0;
            top: 0;
            padding: 10px 0 10px 16px;
            display: flex;
            align-items: center;
        }

        .p-inputtext {
            padding: 10px 16px;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #344054;
        }

        .p-dropdown-items {
            width: 212px;
            position: absolute;
            right: 0;
            top: 5px;
            background-color: white;
            border-radius: 8px;
            overflow: hidden;
            z-index: 100 !important;
            border: 1px solid #EAECF0;
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        }

        span .show-icon {
            display: none;
        }

        .p-highlight {
            background-color: #F9FAFB !important;
            color: #344054 !important;
            font-weight: 600;
            border-radius: 6px;

            span .show-icon {
                display: inline;
            }
        }

        .p-dropdown-item {
            height: unset !important;
            margin: 2px 6px;
        }

        .p-dropdown-item:hover {
            background-color: #F9FAFB !important;
            border-radius: 6px;
        }

        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 16px 10px 0;
        }

        .pi-chevron-down {
            width: 20px;
            height: 20px;
        }

        .pi-chevron-down::before {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 20px;
        }

        .p-overlay {
            z-index: 3 !important;
        }
    }
}

.flight-cancel-booking {
    .p-dialog {
        height: 100%;
        max-height: 95vh !important;
    }

    .p-dialog .p-dialog-content {
        background: #fff !important;
    }

    .p-dialog .p-dialog-header-icons {
        color: #344054;
        top: 24px;
        right: 24px;
        position: absolute;
    }

    // Radio button
    .p-radiobutton .p-radiobutton-box {
        width: 20px;
        height: 20px;
        border: 1px solid #D0D5DD;

        &:hover {
            border-color: #1570EF !important;
        }
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
        background: #EFF8FF;
        border-color: #1570EF;

        &:hover {
            background: #EFF8FF;
        }
    }

    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background-color: #1570EF;
        width: 8px;
        height: 8px;
    }

    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: #1570EF;
        background: #EFF8FF;
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        box-shadow: none !important;
    }

    .p-inputtext:enabled:hover {
        border-color: #2E90FA !important;
        overflow-y: scroll !important;
    }

    .p-inputtext {
        min-height: 44px;
        font-family: 'Inter';
        font-size: 14px !important;
    }
}

.result-list{
    .p-dropdown{
        display: flex;
        align-items: center;
        height: 432px;
        min-height: 32px;
        max-height: 32px;
        width: 100% !important;
        color: #344054;
        font-family: 'Inter' !important;
        font-size: 14px !important;
        font-style: normal;
        line-height: 20px;
        font-weight: 500 !important;
        outline: none;
        background-color: #ffff;
        border: 0 none !important;
        .p-dropdown-trigger {
            width: unset !important;
            padding: 10px 0px 10px 0;
        }
        .p-dropdown-label{
            font-size: 14px !important;
            font-family: 'Inter';
            outline: none;
            color: #344054; 
            padding-left: 0px;
            padding-right: 6px;
            font-weight: 500 !important;
            height: 44px;
            min-height: 44px;
            max-height: 44px;            
            &.p-placeholder{
                color: #98A2B3 !important;
            }
        }
    }
    .p-dropdown-panel {
        border: 0 none !important;
        font-size: 14px !important;
        font-family: 'Inter';
        outline: none;
        .p-dropdown-items{
            margin: 4px;
            .p-dropdown-item{
                font-weight: 500 !important;
            }
        }
    }
    .p-dropdown:not(.p-disabled).p-focus {
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 8px;
        border: 0 !important;
        background: var(--base-white, #FFF);
        box-shadow: none;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        color: #F9FAFB;
        background: #e9ecef;
        border-radius: 8px;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #495057;
        background: #F2F4F7;
        border-radius: 8px;
    }
}

.passengers-nationality {
    @media (max-width: 768px) {
        .p-overlay {
            min-width: 278px !important;
            width: 200px !important;
        }
    }
}

.custom-input {
    .custom-radio {
        .p-radiobutton .p-radiobutton-box {
            width: 20px;
            height: 20px;
            border: 1px solid #D0D5DD;

            &:hover {
                border-color: #1570EF !important;
            }
        }

        .p-radiobutton .p-radiobutton-box.p-highlight {
            background: #EFF8FF;
            border-color: #1570EF;

            &:hover {
                background: #EFF8FF;
            }
        }

        .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
            background-color: #1570EF;
            width: 8px;
            height: 8px;
        }

        .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
            border-color: #1570EF !important;
            background: #EFF8FF !important;
        }

        .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
            border-color: #1570EF;
            background: #EFF8FF;
        }

        .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
            box-shadow: none !important;
        }

        .is-invalid {
            .p-radiobutton .p-radiobutton-box {
                border: 1px solid #d92d20 !important;
            }
        }
    }
}
